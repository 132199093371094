.compact-modal {
    box-shadow: none;
    background-color: transparent;
}

.compact-modal .modal-wrapper{
    box-shadow: none;
    background-color: transparent;
}

.compact-modal ion-card {
    margin: 0;
    padding: 0;
}

.realtor-options-modal .modal-wrapper {
	height: 500px !important;
}

.asset-transfer-approval-modal .modal-wrapper {
	height: 100% !important;
    width: 100% !important;
}

.ipg-evolution-chart-modal .modal-wrapper {
    background-color: transparent;
	height: 550px;
    width: 500px;
    // max-width: 500px;
}

.monthly-organogram-modal .modal-wrapper {
    background-color: transparent;
	height: 100%;
    width: 100%;
}

.chart-modal .modal-wrapper{
    box-shadow: none;
    background-color: transparent;
    height: 250 px;
    max-width: 400px;
}

.person-profile-modal {
    box-shadow: none;
    background-color: transparent;
}

.person-profile-modal .modal-wrapper{
    box-shadow: none;
    background-color: transparent;
    height: 250 px;
    max-width: 400px;
    min-width: 400px;
}

.person-profile-modal ion-card {
    margin: 0;
    padding: 0;
    z-index: 1;
}

person-profile {
    box-shadow: none;
    background-color: transparent;
    height: 250 px;
    max-width: 400px;
    min-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

person-profile ion-card {
    margin: 0;
    padding: 0;
}

.feedback-modal .modal-wrapper {
    height: 600px;
    max-width: 600px;
    min-width: 600px;
}

.feedback-modal ion-card {
    margin: 0;
    padding: 0;
    z-index: 1;
}