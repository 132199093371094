.text-danger {
    color: var(--ion-color-danger) !important;
}
.text-success {
    color: var(--ion-color-success) !important;
}
.text-warning {
    color: var(--ion-color-warning) !important;
}
.text-primary {
    color: var(--ion-color-primary) !important;
}
.text-medium {
    color: var(--ion-color-medium) !important;
}