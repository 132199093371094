@font-face {
    font-family: Lufga;
    src: url("../../../../assets/Lufga-Regular.otf");
}

.item-content-text {
    font-family: Lufga;
    color: var(--ion-color-prestes-green);
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.264);
    font-size: .75em;
    position: absolute;
    bottom: -28px;
    left: -20px;
}

.item-tooltip {
    height: 30px;
    width: 30px;
    position: fixed;
    z-index: 1000;
}
.item-tooltip .tooltip-info {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 115px;
    right: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-tooltip .tooltip-radius {
    background-color: rgba(0, 255, 255, 0.1);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -17px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: background-color, opacity, visibility,
        -webkit-transform;
    transition-property: background-color, opacity, visibility,
        -webkit-transform;
    transition-property: background-color, opacity, visibility, transform;
    transition-property: background-color, opacity, visibility, transform,
        -webkit-transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease, ease, ease,
        cubic-bezier(0.5, 0, 0, 1);
    transition-timing-function: ease, ease, ease, cubic-bezier(0.5, 0, 0, 1);
}
.item-tooltip .tooltip-info:hover .tooltip-radius {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.item-tooltip .tooltip-content {
    color: #fff;
    width: 250px;
    position: absolute;
    left: -25px;
    bottom: -25px;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
    transition: opacity 0.7s ease, visibility 0.7s ease;
    pointer-events: none;
    color: #fff;
    visibility: hidden;
    pointer-events: none;
}
.item-tooltip .tooltip-info:hover .tooltip-content {
    color: #fff;
    width: 250px;
    position: absolute;
    left: -25px;
    bottom: 0px;
    z-index: 5;
    opacity: 1;
    visibility: visible !important;
    -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
    transition: opacity 0.7s ease, visibility 0.7s ease;
    pointer-events: none;
    visibility: hidden;
    pointer-events: none;
}
.item-tooltip .tooltip-content::before {
    width: 0px;
    bottom: -25px;
    left: -25px;
    content: "";
    background-color: #fff;
    height: 1px;
    position: absolute;
    transition: width 0.4s;
}
.item-tooltip .tooltip-info:hover .tooltip-content::before {
    width: 180px;
    transition: width 0.4s;
}
.item-tooltip .tooltip-content::after {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: rotate(-225deg);
    transform: rotate(-225deg);
    bottom: -25px;
    left: -25px;
    width: 40px;
    content: "";
    background-color: #fff;
    height: 1px;
    position: absolute;
    opacity: 1;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.item-tooltip .tooltip-info:hover .tooltip-content::after {
    opacity: 1;
    visibility: visible;
}
.item-tooltip .tooltip-info[data-position="4"] .tooltip-content {
    bottom: 86px;
    left: 50%;
    margin-left: 70px;
}
